import React, { useState, useEffect } from 'react'
import axios from 'axios'
import baseurl from '../constants/baseurl';
import { photography } from "../assets";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function Specialpromo  ()  {

  let settings = {};
  settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const[specialPromo,setSpecialPromo] = useState([]);
  const[status, setStatus] = useState(false);

  useEffect(()=>{
    getLatestProduct();
  },[])

  const getLatestProduct = async () =>{
    try {
      const config={
        method:'GET',
        url:`${baseurl}/specialpromo`
      }
       const response = await axios(config);
      console.log(response.data.data);

      setSpecialPromo(response.data.data);

      
      setStatus(true)


    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="w-full py-[5rem] px-4 bg-slate-100">
       <h4 className="text-4xl font-medium  pb-8 text-black italics text-center">
         <span className='italic'>Rebate</span> on Marketplace
      </h4>
      <Slider {...settings}>
      {/* <div className="max-w-[1240px]  mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4"> */}


        {


          status ? (specialPromo ?.length > 0 ?  
            
            specialPromo.map((item, key) => {

              return <div className="order-product w-full max-w-sm bg-white rounded-lg shadow-md hover:scale-105 duration-300" key={key}> 
              <img
                className="p-8 rounded-t-lg w-[600px] h-[250px] object-contain"
                src={item.data.image.length > 0 ? item.data.image : photography}
                alt="product image"
              />
    
              <div className="px-5 pb-5">
                <h5 className="text-xl font-medium tracking-tight ">
                  {item.data.category}
                </h5>
                <p>{item.data.productName}</p>
                <div className="order-wrapper">
                  {/* <Link to="/cover">to next page</Link> */}
                  <div className='price-wrapper'>
                    <div className='price-new'>New Price: <span>{item.merchant.currencyCode}{item.data.amount}</span></div>
                    <div className='price-old'>Old Price: <span><s>{item.merchant.currencyCode}{item.data.previousAmount}</s></span></div>
                  </div>
                  <a className="order-product-btn" href={`https://paysprint.ca/shop/${item.merchant.businessname}`}>
                    Order Product
                  </a>
                </div>
              </div>
            </div>

            })
          
          
          : 
          
          <div className="px-5 pb-5">
            <h5 className="text-xl font-medium tracking-tight">
              No available store at the moment
            </h5>
            
          </div>
          ) : <p>Loading...</p>
        }









      {/* </div> */}
      </Slider>
    </div>
  );
};

export default Specialpromo;
