import React, { useState, useEffect } from 'react'
import axios from 'axios'
import baseurl from '../constants/baseurl';
import { photography} from "../assets";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Services = () => {

  

  let settings = {};
  settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const[newProduct,setNewProduct] = useState();
  const[status, setStatus] = useState(false);

  useEffect(() => {
    getNewProduct();
  }, []);

  const getNewProduct= async ()=>{
 try {

  
    const config={
      method:'GET',
      url:`${baseurl}/latestmerchant`
    }

    const response = await axios(config);
    console.log(response);

    setNewProduct(response.data.data);
    setStatus(true)
 
  
 } catch (error) {
  console.log(error)
  
 }


  }
  return (
    <div className="w-full py-[2rem] px-4 bg-[#FEFBF6]">
      <h4 className="text-4xl font-medium pb-10 pt-3 text-black text-center">
         <span className='italic'>New</span> on Marketplace
      </h4>
      <Slider {...settings}>
      

        {
          status ? (newProduct.length > 0 ?
            newProduct.map((item,key) =>{
              return<div className="new-service w-full shadow-xl flex flex-col p-4  my-4 rounded-lg hover:scale-105 duration-300 bg-slate-100 " key={key}>
              <div className='card-body'>
              <img
                src={(item.data.headerContent.length > 0 ? (item.data.headerContent).replaceAll(" ", "_") : photography)}
                alt="logo"
                className="w-[300px] h-[100px] object-contain mx-auto mt-[-3rem] bg-white"
              />
              <h2 className="text-2xl font-thin text-center py-4">{item.data.headerTitle}</h2>
              <h2 className="text-1xl font-thin text-center py-4">{item.data.headerSubtitle}</h2>
              <p className="text-center text-1xl font-light">
              {item.data.advertSubtitle}
              </p>
              </div>
             
              <a className="order-news-btn pt-5" href={`https://paysprint.ca/shop/${item.merchant.businessname}`}>
                Go to E-Store
              </a>
            
            </div>
            
            })
           :
           <div className="px-5 pb-5">
           <h5 className="text-xl font-medium tracking-tight text-gray-900 dark:text-white">
             No available store at the moment
           </h5>
           
         </div>) : <p>Loading...</p> 
        }
      
      
     
       
    
      </Slider>
    </div>
  );
};

export default Services;
