import React, { useState, useEffect } from 'react'
import axios from 'axios'
import baseurl from '../constants/baseurl';
import { photography, } from "../assets";
import { Link } from 'react-router-dom'

function News (){

  const [newsList, setNewsList] = useState();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getAllNews();
  }, []);


  const getAllNews = async () => {
    try {

      const config = {
        method: 'GET',
        url: `${baseurl}/getallnews`
      }

      const response = await axios(config);
      setNewsList(response.data.data);
      setStatus(true);
      
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="w-full py-[3rem] px-4 bg-slate-100">
        <h4 className="text-3xl font-bold  pb-8 text-black text-center">
         News and Tips
      </h4>
      <div className="max-w-[1240px]  mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2">
          
          {
          status ? (newsList.length > 0 ?
          newsList.map((item,key) =>{

            return <div className="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 hover:scale-105 duration-300" key={key}>

            <img className=" w-[300px] h-[100px] object-contain" src={item.file.length > 0 ? item.file : photography} alt="beverage" />
            <div className="px-6 py-4">
              <h3 className="font-bold text-xl mb-2 text-blue-400">{item.title}</h3>
              <p className="text-gray-700 text-base">
               {item.description}
              </p>

              <div className="flex justify-between items-center mt-3 mb-5  ">
                  
                  <a
                    href=''
                    className="text-white bg-[#141414]  hover:bg-[#7a6f52] focus:ring-4 focus:outline-none focus:ring-black/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#141414] dark:hover:bg-[#7a6f52] dark:focus:ring-black/50 "
                  >
                    
                    <Link to ="/readmore">Read More</Link>
                  </a>
                </div>
            </div>
         
          </div>
           })
           :
           <div className="px-5 pb-5">
           <h5 className="text-xl font-medium tracking-tight text-gray-900 dark:text-white">
             No news available at the moment
           </h5>
           
         </div>) : <p>Loading...</p> 
        }
      
      
     
       
        </div>
    </div>
  );
};

export default News;
