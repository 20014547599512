import React, {useState} from 'react'
import { ps} from '../assets'

import { Link } from 'react-router-dom'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
  

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <nav className='nav-wrapper'>
      <div className='nav-img-wrapper'>
        <img src={ps} alt="logo" />
      </div>

      <ul className='list-none sm:flex hidden justify-end items-center flex-1 nav-menu'>
          <li className='mr-5 font-light'><a href="https://paysprint.ca">Home</a></li>
          <li className='mr-5 font-light'><Link to ="/">Discover</Link></li>
          <li className='mr-5 font-light'><Link to ="/search">Find a Product/Service</Link></li>
          <li className='mr-5 font-light'><Link to ="/claim">Use Business Checker</Link></li>
          <li className='mr-5 font-light'><Link to ="/refer">Refer & Earn</Link></li>
     
          <li className='mr-5 font-light'><Link to ="/community">Join Our Community</Link></li>
      </ul>

    

     {/* mobile Devices */}
     <div className='t'>
     <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={30}/> : <AiOutlineMenu size={30} />}
      </div>

    

      <div className={`${nav ? 'flex' : 'hidden'} nav-menu-mobile`}>
        <ul className='list-none flex  flex-col  justify-end items-center flex-1'>
          <li className='mr-4'><a href="https://paysprint.ca">Home</a></li>
          <li className='mr-4'><Link to ="/">Discover</Link></li>
          <li className='mr-4'><Link to ="/search">Find a Product/Service</Link></li>
          <li className='mr-4'><Link to ="/claim">Use Business Checker</Link></li>
          <li className='mr-4'><Link to ="/refer">Refer & Earn</Link></li>
       
          <li className='mr-4'><Link to ="/community">Join Our Community</Link></li>
        </ul>
      </div>
     </div>

    </nav>
  )
}

export default Navbar
