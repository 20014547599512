import React from 'react';
import Comment from './components/find_a_product/Comment';
import { BrowserRouter as Router,Route,Routes,} from 'react-router-dom';
import {Footer,Discover, Products, Refer, Support, Community } from  './components'
import { Search,Claim,Review,Submit } from './components/find_a_product';
import Categoryview from './components/Categoryview';
import Business from './components/find_a_product/Business';
import './App.css';
import Claimnumber from './components/find_a_product/Claimnumber';

import Viewcomment from './components/find_a_product/Viewcomment';


const  App = () => (

    <div className='container'>
      <Router>
      
      <Routes>
        
          <Route path="/" element={<Discover/>} />
          <Route path="/product/service" element={<Products/>} />
          <Route path="/refer" element={<Refer/>} />
          <Route path="/support" element={<Support/>} /> 
          <Route path="/community" element={<Community/>} />
          <Route path = "/search" element={<Search/>} />
          <Route path = "/claim" element={<Claim/>} />
          <Route path = "/request" element={<Review/>} />
          <Route path = "/submit" element={<Submit/>} />
          <Route path = "/comment/:id" element={<Comment/>}/>
          <Route path = "/viewmore" element={<Categoryview/>}/>
          <Route path = "/business" element={<Business/>}/>
          <Route path = "/numberclaim" element={<Claimnumber/>}/>
          <Route path = "/viewcomment/:id" element={<Viewcomment/>}/>
      </Routes>
      <Footer/>
      </Router>
    </div>
);

export default App;
