import React, { useState, useEffect } from "react";
import { FaHeart } from 'react-icons/fa';
import axios from "axios";
import baseurl from "../../constants/baseurl";
import { benz } from "../../assets";
import { useNavigate } from 'react-router-dom'

const SearchBusiness = () => {
  const [searchProducts, setSearchProducts] = useState();
  const [searchIndustry, setSearchIndustry] = useState([]);
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    searchProduct();
  }, []);

  const searchProduct = async () => {
    try {
      const config = {
        method: "GET",
        url: `${baseurl}/getallproducts`,
        header: "search",
      };

      const response = await axios(config);
      console.log(response.data.data);

      setSearchProducts(response.data.data);
 
      setStatus(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (id, merchant,product,merchantId) => {

    navigate(`/comment/${id}`, {state: {merchant, product,merchantId}});

  }

  
  const clickComment = (id, merchant,product,image) => {

    navigate(`/viewcomment/${id}`, {state: {merchant, product,id,image}});

  }

  return (
    <>
    <div className="w-full py-[3rem] px-4 bg-slate-100 ">
      <form className="flex items-center">
        <label for="simple-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <input
            type="text"
            id="search"
            onChange={(event)=>{
              setSearchIndustry(event.target.value);
              
            }}
            value={searchIndustry}
            className="bg-gray-50 border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
            placeholder="Search For a Product/Service"
            required=""
          />
        </div>
        <button
          type="submit"
          className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 "
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
          <span className="sr-only">Search</span>
        </button>
      </form>
    </div>
      <div className="search-business-container">
        {
          status ? (searchProducts ?.length > 0 ?
            searchProducts.filter((item)=>{
              if(searchIndustry == ""){
                return item
              }else if((item.data.productName).toLowerCase().includes(searchIndustry.toLowerCase())){
                return item
              }
            })
            .map((item,key)=>{
              return <div className="search-business" key={key}>
                <div className="search-business-logo-wrapper">
                  <img src={item.data.image.length > 0 ? item.data.image:benz} alt="logo" />
                </div>
              
              <div className="description">
                <h3 className="">{item.merchant.businessname}</h3>
                <h4 className="" id="space">{item.data.productName}</h4>
                <h5 className="" id="space-cat">{item.data.category}</h5>
                

                <p className="details">{item.data.description.replace(/(<([^>]+)>)/gi, "")}</p>
              
              <div className="description-btn">
                <button type="button" className="" onClick={() => handleClick(`${item.data.id}`, `${item.merchant.businessname}`,`${item.data.productName}`,`${item.data.merchantId}`)}>Make Comment</button>


                <button type="button" className="" onClick={() => clickComment(`${item.data.id}`, `${item.merchant.businessname}`,`${item.data.productName}`,`${item.data.image}`)}>View Comments ({item.totalcomments})</button>
                <button type="button" className="btn-heart"><FaHeart/><span>{item.likes}</span></button>
                <button type="button" className=""> <a  href={`https://paysprint.ca/shop/${item.merchant.businessname}`}>
                      Connect
                    </a></button>
              </div>
                
              </div>
            </div>
           
           
           
            })
            :
            <div className="px-5 pb-5">
            <h5 className="text-xl font-medium tracking-tight">
              No available store at the moment
            </h5>
            
          </div>
            ):
            <p>Loading...</p>
        }
      
      </div>
      </>
  );
};
export default SearchBusiness;
